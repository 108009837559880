import React, {useState} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppTimeOfDayServiceInsights({ storeID }) {
  const [xAxisList, setXAxisList] = useState(null);
  const [avgServiceTime, setAvgServiceTime] = useState(null);
  const [serviceConsistency, setServiceConsistency] = useState(null);

  const url = `https://client-blue-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/TimeOfDayServiceConsistency`
  try {
    axios.get(url).then(
      response=>{
        const chartInfo = JSON.parse(JSON.stringify(response.data));
        const xAxis = [];
        const aServiceTime = [];
        const sConsistency = [];
        chartInfo.forEach(chart => {
          xAxis.push(chart.TimeOfDay);
          aServiceTime.push(chart.AvgServiceTime);
          sConsistency.push(chart.ServiceConsistency);
        });
        setXAxisList(xAxis);
        setAvgServiceTime(aServiceTime);
        setServiceConsistency(sConsistency);
      }
    );
  } catch (error) {
    console.log('TimeOfDayServiceConsistency failed!');
  }

  const options = {
    chart: {
      id: "basic-bar",
      height: 350,
      type: "bar",
      stacked: false
    },
    xaxis: {
      categories: xAxisList,
    },
    yaxis: [
      {
        seriesName: 'Service Times',
        title: {
          text: "Avg Service Time (Sec)"
        },
        type: "bar",
      },
      {
        seriesName: 'Service Consistency',
        opposite: true,
        title: {
          text: "Service Consistency (%)"
        },
        type: "line",
        min: 1,
        max: 100,
      },
    ]
  };
  const series = [
    {
      name: "Avg. Service Time",
      type: 'column',
      data: avgServiceTime,
    },
    {
        name: "Service Consistency",
        type: 'line',
        data: serviceConsistency,
    }
  ]
  return (
      <Card>
          <CardHeader title="Time of Day Service Consistency (Last 8 Weeks)" />
          <Box sx={{ mx: 3 }} dir="ltr">
          {xAxisList && serviceConsistency ? (
            <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="line"
                  width="90%"
                  />
              </div>
              </div>
            </div>
          ) : (
            <div>Please choose a Store to load this metric...</div>
          )}
          </Box>
      </Card>
  )
}
