// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  // {
  //   title: 'store setup',
  //   path: '/dashboard/user',
  //   icon: icon('ic_site'),
  // },
  // {
  //   title: 'reward management',
  //   path: '/dashboard/products',
  //   icon: icon('ic_rewards'),
  // },
];

export default navConfig;
