import React, {useEffect, useState,} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppBrandLoyalty() {
  const [xAxisList, setXAxisList] = useState();
  const [newCust, setNewCust] = useState();
  const [bronzeCust, setBronzeCust] = useState();
  const [goldCust, setGoldCust] = useState();
  const [silverCust, setSilverCust] = useState();

  useEffect(() => {
    try {
      axios.get('https://client-blue-api.portal.ai.dev.uknomi.com/v1/brand-reports/BrandLoyalty').then(
        response=>{
          const chartInfo = JSON.parse(JSON.stringify(response.data));
          chartInfo.forEach(chart => {
            setXAxisList(chart.WeekNum.split(","));
            if(chart.status === "New") {
              setNewCust(chart.status_values.split(","));
            }
            if(chart.status === "Bronze") {
              setBronzeCust(chart.status_values.split(","));
            }
            if(chart.status === "Silver") {
              setSilverCust(chart.status_values.split(","));
            }
            if(chart.status === "Gold") {
              setGoldCust(chart.status_values.split(","));
            }
          });
        }
      );
    } catch (error) {
      console.log('BrandLoyalty failed!');
    }
  }, [])

  const options = {
    chart: {
      type: 'basic-bar',
      height: 350,
      stacked: true,
    },
    xaxis: {
      categories: xAxisList,
    },
    yaxis: [
      {
        seriesName: 'Gold',
        title: {
          text: "Loyal Tier (%)"
        },
        min: 0,
        max: 100,
      },
    ],
    colors: ['#87ceeb', '#cd7f32', '#d3d3d3', '#ffd700'  ],
  };
  const series = [
    {
      name: "New",
      data: newCust
    },
    {
      name: "Bronze",
      data: bronzeCust
    },
    {
      name: "Silver",
      data: silverCust
    },
    {
      name: "Gold",
      data: goldCust
    }
  ]
  return (
      <Card>
          <CardHeader title="Loyal Customers Trends" />
          <Box sx={{ mx: 3 }} dir="ltr">
          <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="90%"
                  />
              </div>
              </div>
          </div>
          </Box>
      </Card>
  )
}
